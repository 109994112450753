import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ShowLoading from "global/components/ShowLoading";
class CheckObjList extends HFormUser {
    componentDidMount() { this.readApi(); }
    readApi() {
        let stDataKey = { idKey: "0" }
        if (this.props.location.state)
            stDataKey = { idKey: this.props.location.state.detKey }
            this.post(Api.checkObjList, stDataKey)
            .then(data => { this.stateHarrys(this, data) })
    }
    btPublicar = row => { 
        let stDataKey = { idKey: "0" }
        //stDataKey = { idKey: this.props.location.state.detKey }
        this.post(Api.checkObjPub, stDataKey)
            .then(data => { this.stateHarrys(this, data) })
        }
    btCrear = row => { this.btEdit({ xrowid: -1, nombre: "Nuevo", idpadre: -1 }) }
    btEdit = row => {
        //console.log(row)
        apiRuta.toRutaAdd(this, "Edit", Api.checkObjEditRoute, { detKey: row })
    };
    btTree = row => {
        apiRuta.toRutaAdd(this, "Tree", Api.checkObjTreeARoute, { detKey: row })
    };

    render() {
        if (this.state.loading) return (<ShowLoading />)
        const titulos = [
            { id: "codigo", label: "Código", align: "left" }
            , { id: "xtipo", label: "Tipo", align: "left" }
            , { id: "nombre", label: "Descripción", align: "left" }
            , { id: "numevento", label: "Eventos", align: "left" }
            , { id: "tot_por_ava", label: "Avance", align: "right" }
            , { id: "tot_hijos", label: "Item's", align: "right" }
            , { id: "bt_Asig", label: "Editar", align: "center", accion: this.btEdit, clsIcono: "fa fa-edit" }
            , { id: "bt_Check", label: "Check", align: "center", accion: this.btEdit, clsIcono: "fa fa-check" }
            , { id: "bt_Tree", label: "Arbol", align: "center", accion: this.btTree, clsIcono: "fa fa-sitemap" }
        ]
        return (<ContainerRightTable
            tituloContainer="Objetos"
            titulos={titulos}
            resObj={this.state.resObj}
            bt1Click={this.btCrear}
            bt1Icon="fas fa-plus"
            bt2Click={this.btPublicar}
            bt2Label="Publicar Productos"
            bt2Icon="fas fa-plus"
        />
        );
        //reCrear={this.startReCrear}
    }

}
export default WithNavigate(CheckObjList);