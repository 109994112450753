import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "../ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightTable from "global/components/Page/ContainerRight/ContainerRightTable";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import ShowError from "global/components/ShowError";
import HBoxButtonCrud from "global/components/Page/InputHtml/HBoxButtonCrud";
import HBoxTextCod from "global/components/Page/InputHtml/HBoxTextCod";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextNum from "global/components/Page/InputHtml/HBoxTextNum";
import HBoxTextArea from "global/components/Page/InputHtml/HBoxTextArea";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";

class CheckListDatChk extends HFormUser {


    componentDidMount() { this.readApi(); }
    readApi() {
        //this.post(Api.checkLisDat,{idKey:this.props.location.state.detKey})
        //		.then(data=>{this.stateHarrys(this,data)})     
        this.post(Api.checkObjChEditList, { regKey: this.props.location.state.detKey })
            .then(data => {//console.log(data);
                this.setState({
                    dataCam: data.obj.daCam
                    , dataObj: data.obj.daObjCh
                    , dataChk: data.obj.daChk
                    , dataEst: data.obj.estIns
                    , loading: false
                    , resObj: { rows: data.obj.tabHijas }
                    , resTab: { rows: data.obj }
                    , tabHelp: data.obj.help
                    , tabPrg: data.obj.regPrg
                })
            })
    }
    btEdit = row => {
        //console.log(idReg)
        apiRuta.toRutaAdd(this, "CheckEdit", Api.frontCheckListDat, { detKey: row })
    };
    render() {
        if (this.state.loading) return (<ShowLoading />)
        //    if (this.state.swError) return (<ShowError msg={this.state.msg}/>)
        //console.log("data" , this.state.data)
        //console.log("help",this.state.tabHelp)
        return (
            <div>
                {this.state.resObj.rows.map((reg, iCol) => {
                    let arr_pro = this.state.resTab.rows['arrPro_' + reg.codigo.toLowerCase()]
                    let titulos_det = [
                        { id: "numevento", label: "Nro.", align: "left" },
                        { id: "fprogra", label: "Fecha", align: "left" },
                    ]
                    for (var regCam of arr_pro) {
                        titulos_det.push({ id: regCam.codigo.toLowerCase(), label: regCam.codigo.toLowerCase(), align: "left" })
                    }
                    titulos_det.push({ id: "bt_Asig", label: "Editar", align: "center", accion: this.btEdit, clsIcono: "fas fa-edit"  })
                    const arr_data = this.state.resTab.rows['arr_' + reg.codigo.toLowerCase()]
                    //console.log("arr_data:=>",arr_data)
                    //rows={this.state.tabPrg}
                    return <ContainerRightTable
                        tituloContainer={reg.descripcion}
                        tituloTable='Data'
                        titulos={titulos_det}
                        refresh={this.startRefresh}
                        
                        rows={arr_data}
                        bt1={this.startRefresh}
                        bt1Label="Refrescar"
                    />
                })}
            </div>
        );
    }
    //                resObj={this.state.resObj}

}
export default WithNavigate(CheckListDatChk);