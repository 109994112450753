import React, { Component } from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "modCheckList/ApiCheckList";
import apiRuta from "global/utils/funRuta";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ContainerRightEdit from "global/components/Page/ContainerRight/ContainerRightEdit";
import ShowLoading from "global/components/ShowLoading";
import HBoxHtmlCkEd from "global/components/Page/InputHtml/HBoxHtmlEdit/HBoxHtmlCkEd";
import HBoxForm from "global/components/Page/InputHtml/HBoxForm";
import HBoxText from "global/components/Page/InputHtml/HBoxText";
import HBoxTextArea from "global/components/Page/InputHtml/HBoxTextArea";
import HBoxTextCod from "global/components/Page/InputHtml/HBoxTextCod";
import HBoxTextNum from "global/components/Page/InputHtml/HBoxTextNum";
import HBoxSelect from "global/components/Page/InputHtml/HBoxSelect";



class CheckObjEdit extends HFormUser {
    dataActiva = [{ name: 1, id: 1, descripcion: 'Activo' }
        , { name: 2, id: 2, descripcion: 'En Desarrollo' }
        , { name: 3, id: 3, descripcion: 'Por Realizar' }]


    componentDidMount() {  this.readApi(); this.readTipo(); }
    readApi() {
        console.log("ReadApi:",this.props.location.state.detKey)
        this.post(Api.checkObjEdit, { idKey: this.props.location.state.detKey.xrowidobj })
            .then(data => { this.stateHarrys(this, data) })
    }

    actualizarApi() {
        if (document.getElementById("cbTipo").value == '-1harrys') {
            alert("Seleccione un  Tipo de Objeto")
            return
        }
        var stData = {
            idKey: this.props.location.state.detKey.xrowidobj
            //,id_padre_ori:this.props.location.state.detKey
            , codigo: document.getElementById("txCodigo").value
            , secuencia: document.getElementById("txSec").value
            , descrip: this.childHtml.getDataHtml()
            , nombre: document.getElementById("txNombre").value
            , id_padre: this.txIdPadre.getId()
            , cbtipo: document.getElementById("cbTipo").value
        }
        this.post(Api.checkObjAct, stData)
            .then(data => { if (data.ok) apiRuta.toRutaBack(this) })
    }
    helpPadre = (obj) => {
        document.getElementById("txIdPadre").value = obj.xrowid + "/" + obj.codigo + "/" + obj.nombre
        obj.valueid = obj.xrowid
    }

    readTipo() {
        this.post(Api.checkObjTipList)
            .then(data => { this.setState({ dataTip: data.rows }) })
    }


    render() {
        if (this.state.loading) return (<ShowLoading />)

        let stNombre = this.state.regData.nombre
        if (this.state.regData.nombre == null) stNombre = "Nuevo"
        if (this.state.regData.nombre_padre == null) this.state.regData.nombre_padre = this.props.location.state.detKey.nombre_padre
        //console.log("data",this.state.regData)

        return (<ContainerRightEdit
            tituloContainer={(this.state.regData.nombre_padre + ' / ' + stNombre)}
            contexto={this}
            resObj={this.state.resObj}
            bt1Click={() => this.actualizarApi()}
            bt1Icon="fas fa-save"
        >
            <HBoxForm>
                <HBoxText
                    label="Código"
                    id="txCodigo"
                    title="Código del Objeto"
                    maxLength="20"
                    onClickHelp={this.helpRamo}
                    value={this.state.regData && this.state.regData.codigo}
                />
                <HBoxText
                    label="Nombre"
                    id="txNombre"
                    title="Título"
                    value={this.state.regData && this.state.regData.nombre}
                    maxLength="100"
                    size="50"
                />
                
                <HBoxHtmlCkEd
                    id="txDescrip"
                    label="Descripción"
                    ref={x => { this.childHtml = x; }}
                    dataHtml={this.state.regData && this.state.regData.descrip}
                    valueChanged={this.valueChanged}
                />

                <HBoxSelect
                    label="Tipo Item"
                    id="cbTipo"
                    value={this.state.regData && this.state.regData.xrowidtip}
                    option={this.state.dataTip}
                />
                <HBoxTextCod disabled
                    label="Padre"
                    id="txIdPadre"
                    ref={x => this.txIdPadre = x}
                    maxLength="15"
                    title="Padre"
                    value={this.props.location.state.detKey.xrowidpadre + "/" + this.state.regData.nombre_padre}
                    valueid={this.props.location.state.detKey.xrowidpadre}

                    sqlHelp="hlp_chk_obj_padre"
                    onClickHelp={this.helpPadre}
                />
                <HBoxTextNum
                    label="Secuencia"
                    id="txSec"
                    value={this.state.regData && this.state.regData.secuencia || -1}
                    minLength="1"
                    maxLength="4"
                    size="4"
                    style={{ width: '70px' }}
                />
                <HBoxText disabled
                    label="idObj"
                    visibility={true}
                    id="txId"
                    maxLength="15"
                    title="Código"
                    value={this.state.regData && this.state.regData.xrowidobj}
                />
                <HBoxText disabled
                    label="idArb"
                    visibility={true}
                    id="txId"
                    maxLength="15"
                    title="Código"
                    value={this.state.regData && this.state.regData.xrowidarb}
                />
            </HBoxForm>
        </ContainerRightEdit>
        );
    }

}
export default WithNavigate(CheckObjEdit);